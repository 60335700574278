/* Add your customizations of the theme here */
//.p-menu {
//  .p-menu-list {
//    .p-menuitem.active {
//      .p-menuitem-link {
//        .p-menuitem-text {
//          color: $primaryColor !important;
//        }
//      }
//    }
//  }
//}
//
//.p-menu .active .p-menuitem-link .p-menuitem-text {
//  color: $primaryColor !important;
//}
$yellow: rgb(255, 183, 77);

.p-divider-solid {
  background-color:  rgba(255, 255, 255, 0.2) !important;
  height: 1px !important;
}

.icon-engine {
  display: inline-block;
  width: 1rem; /* Zwiększenie rozmiaru SVG */
  height: 1rem;
  background-image: url('/assets/icons/engine.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: -0.2rem; /* Obniżenie ikony */
  margin-left: -0.2rem;
  margin-right: 0.30rem;
  color: inherit; /* Użyj koloru dziedziczonego (wymaga currentColor w SVG) */
  filter: invert(1); /* Zamienia czarne na białe dla SVG bez currentColor */
}

.price {
  color: $yellow;
}
