/* Add your customizations of layout here */
$textShadow: -1px 3px 2px rgb(0, 0, 0);
.landing-container {
  .linear-gradient-bottom {
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(18,18,18,.9) 0%, rgba(18,18,18,0) 100%);
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  .linear-gradient-top {
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(18,18,18,.9) 0%, rgba(18,18,18,0) 100%);
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  .header-menu-container {
    position: relative;
    z-index: 1;
  }
  h1 {
    color: #4285F4;
    font-weight: normal !important;
    //line-height: 1.5;
    margin-bottom: 0;
    text-align: center;
  }
  h2 {
    text-align: center;
  }

  #header {
    background: url(../layout/images/landing/landing-header1.webp) top center no-repeat #121212;
    background-size: cover;
    background-position-y: 60%;
    position: relative;

    .title-sentence {
      top: 100px;
      position: absolute;
      font-weight: 400 !important;
    }
  }

  #features {
    position: relative;
    p {
      text-align: justify !important;
    }
  }

  #vintage {
    height: 250px;
    width: 100%;
    background: url(../layout/images/landing/vintage-garage.webp) top center no-repeat #121212;
    background-size: cover;
    background-position-y: 70%;
  }

  #promotion {
    background: url(../layout/images/landing/promotion1.webp) top center no-repeat #121212;
    background-size: cover;
    background-position-y: 90%;
    background-position-x: -270px;
  }

  #video {
    img {
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 991px) {
  .landing-container {
    #header {
      .header-menu-container {
        background: rgba(33, 33, 33, 0.9);
        padding: 0 1rem;

        #menu {
          background-color: rgba(33, 33, 33, 0.9);
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .landing-container{
    #header {
      height: 420px;
      background-size: 540px;
      background-position-x: -60px;
    }
    #promotion {
      background-size: contain;
      background-position-y: unset;
      background-position-x: unset;
      .promotion-text {
        margin-top: 15% !important;
      }
    }
  }
}

@media screen and (min-width: 501px) and  (max-width: 991px) {
  .landing-container{
    h1 {
      color: #4285F4;
      font-weight: 500 !important;
    }
    #header {
      height: 300px;
      background-size: cover;
    }

    #promotion {
      background-size: contain;
      background-position-y: unset;
      background-position-x: unset;
      .promotion-text {
        margin-top: 40% !important;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .landing-container{
    #header {
      height: 500px;
    }
    #promotion {
      height: 600px;
      background-position-y: 90%;
      background-position-x: unset;
    }
  }
}

.grayscale {
  color: #fff;
  text-shadow: $textShadow;
}

.countdown-wrapper {
  position: absolute;
  z-index: 1;
  bottom: -5px;
  h3 {
    font-weight: 400 !important;
    //color: #4285f4;
    //text-shadow: $textShadow;
    margin: 0;
  }
  .countdown {
    width: 100%;
    height: 80px;
    .measurements-number, .measurements-number {
      text-align: center;
      font-size: 20px;
      text-shadow: $textShadow;
    }
    .measurements {
      color: white;
      margin-right: 5px;
      padding: 10px;
    }

    .divider {
      font-size: 30px;
    }
  }
}

.layout-topbar .layout-topbar-left {
  height: 3.2rem;
}

.layout-wrapper .layout-main {
  min-height: calc(100vh - 3.2rem);
  padding-top: 0;
}


.admin.layout-wrapper .layout-main {
  position: relative;
  padding-bottom: 112px;
  min-height: 100vh;
}

app-admin-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-item.layout-search-item .layout-search-panel {
  height: 3.2rem;

  .p-inputgroup-addon {
    padding: 0.25rem;
  }
}

.layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-item .layout-topbar-action-panel {
  top: 3.2rem;
}

.menu-wrapper, .layout-rightmenu.p-sidebar {
  height: calc(100% - 3.2rem);
  top: 3.2rem;
}

li.layout-menuitem-text {
  font-size: 0.857rem;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  padding-left: 0.5rem;
  margin-top: 0.5rem;
}

.menu-wrapper .layout-menu-container .layout-menu li.layout-root-menuitem > div {
  margin-top: 0.5rem;
}

@media (max-width: 991px) {
  .layout-wrapper {
    .menu-wrapper {
      height: calc(100% - 3.2rem);
      top: 3.2rem;
    }
  }
  .layout-topbar .layout-topbar-left {
    height: 3.2rem;
  }
  .layout-wrapper.layout-topbar-mobile-active .layout-rightmenu {
    top: 11rem;
    height: calc(100% - 11rem);
  }
  .layout-wrapper.layout-topbar-mobile-active .menu-wrapper {
    top: 11rem;
    height: calc(100% - 11rem);
  }
}

#app-logo {
  height: 50px;
  width: 100px;
}

.footer {
  height: 42px;
  position: fixed;
  bottom: -4px;
  width: 100%;
  z-index: 100;
  background-color: #1e1e1e;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.12), 0px -4px 5px rgba(0, 0, 0, 0.14), 0px -2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  .card {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

.layout-menu {
  position: absolute;
  top: 435px !important;
  bottom: 90px !important;
  overflow-y: auto;
}

.layout-inline-menu-action-panel {
  position: relative;
  z-index:1;
}

.bottom-container {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  background-color: #1e1e1e;
}

.layout-config-button.p-button {
  position: unset;
  top:unset;
}

.layout-topbar:not(.admin .layout-topbar) {
  top: -3.3rem !important;
  position: sticky !important;
}

app-breadcrumb {
  position: sticky;
  top: -1px;
  left: 0;
  z-index: 20;
}

app-admin-breadcrumb {
  position: fixed;
  top: 3.2rem;
  left: 0;
  z-index: 20;
  width: 100%;
}

.admin.layout-wrapper .layout-main .layout-content {
  padding-top: 7rem;
}

.footer .p-button {
  width: 2.5rem;
  height: 2.5rem;
}

.hoverable:hover {
  background: rgba(255, 255, 255, 0.12);
  transition: background-color 0.2s;
}

.sticky-below-nav {
  position: sticky !important;
  top: 33px; //3.2rem;
  left: 0;
}

.mat-tab-header {
  z-index: 2;
  @extend .sticky-below-nav
}

.layout-topbar-action-item {
  padding: unset !important;
  a {
    width: 100%;
    padding: 1rem;
  }
}

@media (min-width: 992px) {
  .layout-topbar {
    height: 3.2rem;
  }

  .social-list.app-modal {
    height: 60vh !important;
    width: 50vh !important;
  }
}

.sheet-container .sheet {
  z-index: 101 !important;
}


.p-highlight {
  .img-thumbnail {
    border: 2px solid rgba(255, 255, 255, 0.87) !important
  }
  border-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-danger-alt {
  color: #EF9A9A !important;
}

.p-badge.p-badge-md {
  font-size: 1rem;
  min-width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
}

.p-inputnumber-clear-icon {
  right: 0.75rem
}

//.layout-mask {
//  z-index: 5003
//}

.ql-editor.ql-blank::before {
  color: rgba(255, 255, 255, 0.6) !important
}

.p-skeleton.p-skeleton-circle {
  border-radius: 50%;
}


@media (max-width: 768px) {
  /* Ukryj scrollbary w trybie mobilnym */

  /* WebKit (Chrome, Safari, Edge) */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  html, body {
    scrollbar-width: none; /* "auto" or "thin" */
  }

  /* Dla elementów z overflow: auto lub overflow: scroll */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }


  .p-dialog .p-dialog-content {
    padding: 0 0.75rem 1.25rem 0.75rem;
  }
}
